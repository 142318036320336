import React from 'react'
import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
export default class SubscribeStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="flex flex-col w-full place-items-center">
        <ReactWOW animation="fadeIn" delay="0.2s">
          <div className="text-4xl font-medium">Almost there!</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="0.4s">
          <div className="mt-10 text-3xl font-medium">What's your name?</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="0.6s">
          <div className="flex max-w-2xl w-full">
            <div className="flex mt-6 w-1/2">
              <input
                className="flex w-full mr-1 text-2xl"
                name="firstName"
                placeholder="First Name"
                style={{ color: 'black', borderRadius: '5px', padding: '8px' }}
                value={this.props.parentState.firstName}
                onChange={e => this.props.onChange(e)}
              />
            </div>
            <div className="flex mt-6 w-1/2">
              <input
                className="flex w-full ml-1 text-2xl"
                name="lastName"
                placeholder="Last Name"
                style={{ color: 'black', borderRadius: '5px', padding: '8px' }}
                value={this.props.parentState.lastName}
                onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="0.8s">
          <div className="mt-6 text-3xl font-medium">
            {' '}
            Enter your email for free breathing exercises!
          </div>
        </ReactWOW>

        <ReactWOW animation="fadeIn" delay="1s">
          <input
            className="mt-6 max-w-2xl text-2xl w-full"
            style={{ color: 'black', borderRadius: '5px', padding: '8px' }}
            name="email"
            placeholder="firstlast@email.com"
            value={this.props.parentState.email}
            onChange={e => this.props.onChange(e)}
          />
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1.2s">
          <div className="flex flex-row w-full justify-center mt-6">
            {this.props.current > 2 ? (
              <button
                className="btn-light-outline text-2xl font-medium mx-4 w-full lg:w-1/6"
                onClick={this.props.prev}
              >
                Previous
              </button>
            ) : (
              ''
            )}
            <button
              className="btn-light-outline text-2xl font-medium  mx-4 w-full lg:w-1/6"
              onClick={() => this.props.onSubmit()}
            >
              Submit
            </button>
          </div>
        </ReactWOW>
      </div>
    )
  }
}

import React from 'react'
// import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
export default class FirstTimeStep extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {
    return (
      <div className="flex flex-col w-full place-items-center">
        <ReactWOW animation="fadeIn" delay="0.2s">
          <div className="text-3xl font-medium mt-6">
            Is it your first time taking the BIQ?
          </div>
        </ReactWOW>
          <div className="flex flex-row w-full md:w-6/12 flex-wrap justify-center">
            <ReactWOW animation="fadeIn" delay="0.4s">
              <div className="flex w-1/2 xl:w-1/3 px-3 justify-center place-items-center mt-4">
                <button
                  className={"btn-sec-outline w-full"}
                  name="isFirstTime"
                  value={this.props.getState('isFirstTime', null)}
                  type="button"
                  onClick={() => {this.props.setState('isFirstTime', true); this.props.next();}}
                >
                  Yes
                </button>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeIn" delay="0.6s">
              <div className="flex  w-1/2 xl:w-1/3 px-3 justify-center place-items-center mt-4">
                <button
                  className="btn-sec-outline w-full"
                  name="isFirstTime"
                  value={this.props.getState('isFirstTime', null) === false}
                  type="button"
                  onClick={() => {this.props.setState('isFirstTime', false); this.props.next();}}
                >
                  No
                </button>
              </div>
            </ReactWOW>
          </div>
      </div>
    )
  }
}

import React from 'react'
import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
export default class AgeStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="flex flex-col w-full place-items-center">
        <ReactWOW animation="fadeIn" delay="0.2s">
          <div>How old are you?</div>
        </ReactWOW>
        <div className="flex flex-row flex-wrap w-6/12 justify-center">
          <ReactWOW animation="fadeIn" delay="0.1s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">Under 18</button>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeIn" delay="0.2s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">18-24</button>
            </div>
          </ReactWOW>

          <ReactWOW animation="fadeIn" delay="0.3s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">25-34</button>
            </div>
          </ReactWOW>

          <ReactWOW animation="fadeIn" delay="0.4s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">35-44</button>
            </div>
          </ReactWOW>

          <ReactWOW animation="fadeIn" delay="0.5s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">45-54</button>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeIn" delay="0.6s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">55-64</button>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeIn" delay="0.7s">
            <div className="flex w-1/3 px-3 justify-center place-items-center mt-4">
              <button className="btn-sec-outline w-full">65+</button>
            </div>
          </ReactWOW>
        </div>
        {/* <ReactWOW animation="fadeIn" delay="0.2s">
                    <input
                        name="fname"
                        value={this.props.getState("fname", "")}
                        onChange={this.props.handleChange}
                    />
                </ReactWOW> */}
            <button
              className="btn-light-outline text-2xl font-medium mx-4 w-1/6"
              onClick={console.log}
            >
              Previous
            </button>    
        <>
          {this.props.current > 2 ? (
            <button
              className="btn-light-outline text-2xl font-medium mx-4 w-1/6"
              onClick={this.props.prev}
            >
              Previous
            </button>
          ) : (
            ''
          )}
          <button
            className="btn-light-outline text-2xl font-medium  mx-4 w-1/6"
            onClick={this.props.next}
          >
            Next
          </button>
        </>
      </div>
    )
  }
}

import React from 'react'
import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
export default class MeasurementStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row w-full">
          <div className="flex flex-col xl:flex-row place-items-center w-full">
            <div className="flex flex-col place-items-center w-full xl:w-1/2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CxKKisY7oqk?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="mt-6">
                Watch this video to learn about the BIQ
              </div>
            </div>
            <div className="flex flex-col place-items-center w-full xl:w-1/2 mt-6">
              <ReactWOW animation="fadeIn" delay="0.4s">
                <div className="text-3xl font-medium">Location of Movement</div>
              </ReactWOW>
              <ReactWOW animation="fadeIn" delay="0.4s">
                {/* 1 = Vertical
      2 = Horizontal
      3 = Hybrid */}
                <div className="flex flex-col xl:flex-row flex-wrap w-full justify-center place-items-center">
                  <ReactWOW animation="fadeIn" delay="0.4s">
                    <div className="flex w-2/3 md:w-1/3 px-3 justify-center place-items-center mt-4">
                      <button
                        className="btn-sec-outline w-full"
                        name="type"
                        type="button"
                        onClick={e =>
                          this.props.onChange({
                            target: { name: 'type', value: '2' },
                          })
                        }
                        value={this.props.parentState.type === '2'}
                      >
                        Horizontal
                      </button>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="fadeIn" delay="0.6s">
                    <div className="flex w-2/3 md:w-1/3 px-3 justify-center place-items-center mt-4">
                      <button
                        className="btn-sec-outline w-full"
                        name="type"
                        type="button"
                        onClick={e =>
                          this.props.onChange({
                            target: { name: 'type', value: '1' },
                          })
                        }
                        value={this.props.parentState.type === '1'}
                      >
                        Vertical
                      </button>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="fadeIn" delay="0.8s">
                    <div className="flex w-2/3 md:w-1/3 px-3 justify-center place-items-center mt-4">
                      <button
                        className="btn-sec-outline w-full"
                        name="type"
                        type="button"
                        onClick={e =>
                          this.props.onChange({
                            target: { name: 'type', value: '3' },
                          })
                        }
                        value={this.props.parentState.type === '3'}
                      >
                        Hybrid
                      </button>
                    </div>
                  </ReactWOW>
                </div>
              </ReactWOW>
              <ReactWOW animation="fadeIn" delay="1s">
                <div className="mt-10 text-3xl font-medium">
                  Range of Motion
                </div>
              </ReactWOW>
              <div className="flex flex-col">
                <div className="flex flex-row place-items-center mt-6">
                  <ReactWOW animation="fadeIn" delay="1.2s">
                    <div className="mr-3 w-full">Inhale:</div>
                  </ReactWOW>
                  <ReactWOW animation="fadeIn" delay="1.4s">
                    <input
                      style={{
                        color: 'black',
                        borderRadius: '5px',
                        padding: '8px',
                        width: '100px',
                        textAlign: 'right',
                      }}
                      type="number"
                      className="mr-3 text-2xl"
                      name="inhale"
                      value={this.props.parentState.inhale}
                      onChange={e => this.props.onChange(e)}
                    />
                    {this.props.parentState.isMetric === true ? (
                      <div className="flex">cm.</div>
                    ) : (
                      <div className="flex">in.</div>
                    )}
                  </ReactWOW>
                </div>

                <div className="flex flex-row place-items-center mt-6">
                  <ReactWOW animation="fadeIn" delay="1.6s">
                    <div className="mr-3 w-full">Exhale:</div>
                  </ReactWOW>
                  <ReactWOW animation="fadeIn" delay="1.8s">
                    <input
                      style={{
                        color: 'black',
                        borderRadius: '5px',
                        padding: '8px',
                        width: '100px',
                        textAlign: 'right',
                      }}
                      type="number"
                      className="mr-3 text-2xl"
                      name="exhale"
                      value={this.props.parentState.exhale}
                      onChange={e => this.props.onChange(e)}
                    />
                    {this.props.parentState.isMetric === true ? (
                      <div className="flex">cm.</div>
                    ) : (
                      <div className="flex">in.</div>
                    )}
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactWOW animation="fadeIn" delay="1.8s">
          <div className="flex flex-row w-full justify-center mt-6">
            {this.props.current > 2 ? (
              <button
                className="btn-light-outline text-2xl font-medium mx-4 w-1/2 xl:w-1/6"
                onClick={this.props.prev}
              >
                Previous
              </button>
            ) : (
              ''
            )}
            <button
              className="btn-light-outline text-2xl font-medium  mx-4 w-1/2 xl:w-1/6"
              onClick={this.props.next}
            >
              Next
            </button>
          </div>
        </ReactWOW>
      </div>
    )
  }
}

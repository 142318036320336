import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'
import { Step, Steps } from 'react-step-builder'
import ReactWOW from 'react-wow'
import Cookies from 'universal-cookie'

import Layout from '../../components/layout'
import AgeStep from '../../components/steps/AgeStep'
import FirstTimeStep from '../../components/steps/FirstTimeStep'
import HeightWeightStep from '../../components/steps/HeightWeightStep'
import MeasurementStep from '../../components/steps/MeasurementStep'
import SubscribeStep from '../../components/steps/SubscribeStep'
import GenderStep from '../../components/steps/GenderStep'
export const query = graphql`
  query BIQQuery {
    allFile(filter: { relativeDirectory: { eq: "biq/steps" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
{
  /* <Step title="First Time Step" props={this.props} component={FirstTimeStep} onChange={this.handleChange}/>
                    <Step title="Gender And Age Step" props={this.props} component={GenderStep} state={this.state} onChange={this.handleChange}/>
                    <Step title="Height and Weight Step" props={this.props} component={HeightWeightStep} />
                    <Step title="BIQ Measurements Step" props={this.props} component={MeasurementStep} />
                    <Step title="Name and Email Step" props={this.props} component={SubscribeStep} /> */
}
class BIQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // Step One
      isFirstTime: null,
      // STEP Two
      gender: null,
      age: '',
      // Step Three
      isMetric: false,
      height1: null,
      height2: null,
      weight: null,
      // Step Four
      type: null,
      inhale: '',
      exhale: '',
      isTypeInvalid: false,
      isInhaleInvalid: false,
      isExhaleInvalid: false,
      // Step Five
      firstName: '',
      lastName: '',
      email: '',
      // Calculations
      letterGrade: null,
      percentGrade: null,
      coachEmail: '',
      friendEmail: '',
      warningMessage: '',
      errorMessage: '',
      isPopupActive: false,
      popUpMessage: '',
      isStepOneDone: false,
      isStepTwoDone: false,
      stepperChoice: 0,
      current: 1,
      isStepperActive: true,
    }
    console.log(this.state)
  }

  componentDidMount() {}

  handleChange = event => {
    const label = event.target.name
    const value = event.target.value
    if (label == 'exhale' || label == 'inhale') {
      let warningMessage = ''
      if (label == 'exhale') {
        // HANDLE IF EXHALE IS UDPATED
        if (this.state.inhale != '') {
          if (+value > +this.state.inhale) {
            warningMessage =
              'Your exhale measurement is greater than your inhale measurement.'
          } else {
            warningMessage = ''
          }
        }
      } else {
        // HANDLE IF INHALE IS UDPATED
        if (this.state.exhale != '') {
          if (+this.state.exhale > value) {
            warningMessage =
              'Your exhale measurement is greater than your inhale measurement.'
          } else {
            warningMessage = ''
          }
        }
      }
      this.setState({ [label]: value, warningMessage: warningMessage })
    } else {
      this.setState({ [label]: value })
    }
  }

  toggleShowPopUp = event => {
    let isPopupActive = !this.state.isPopupActive
    let isStepperActive = !this.state.isStepperActive
    this.setState({
      isPopupActive,
      isStepperActive,
    })
  }

  handleSubmit = event => {
    let errorMessage = ''
    let isTypeInvalid = false
    let isInhaleInvalid = false
    let isExhaleInvalid = false
    let isPopupActive = false
    let popUpMessage = ''
    if (this.state.type === null) {
      isTypeInvalid = true
    }

    if (this.state.inhale.length === 0) {
      isInhaleInvalid = true
    }

    if (this.state.exhale.length === 0) {
      isExhaleInvalid = true
    }

    if (!isTypeInvalid && !isInhaleInvalid && !isExhaleInvalid) {
      const firstResult = this.state.inhale - this.state.exhale
      const secondResult = firstResult / this.state.exhale
      const percentGrade = secondResult * 1000
      let letterGrade = ''
      // Vertical = 1
      // Horizontal = 2
      // Hybrid = 3
      switch (this.state.type) {
        case '1':
          if (percentGrade < 60) {
            letterGrade = 'F'
          } else if (percentGrade < 80) {
            letterGrade = 'F'
          } else if (percentGrade < 100) {
            letterGrade = 'D'
          } else if (percentGrade < 120) {
            letterGrade = 'C'
          } else {
            letterGrade = 'C'
          }
          break
        case '2':
          if (percentGrade < 60) {
            letterGrade = 'C'
          } else if (percentGrade < 80) {
            letterGrade = 'C'
          } else if (percentGrade < 100) {
            letterGrade = 'B'
          } else if (percentGrade < 120) {
            letterGrade = 'A'
          } else {
            letterGrade = 'A'
          }
          break
        case '3':
          if (percentGrade < 60) {
            letterGrade = 'D'
          } else if (percentGrade < 80) {
            letterGrade = 'D'
          } else if (percentGrade < 100) {
            letterGrade = 'C/B'
          } else if (percentGrade < 120) {
            letterGrade = 'B'
          } else {
            letterGrade = 'B'
          }
          break

        default:
          break
      }
      if (letterGrade === 'D' || letterGrade === 'F') {
        popUpMessage =
          'Don’t worry, 80% of people score a D or below. It’s a good thing you are here, we can get you to an A in no time.'
      } else if (letterGrade.includes('C') || letterGrade.includes('B')) {
        popUpMessage =
          'Congratulations! You are in the top 20%. With a little work correcting your mechanics, we can get to strengthening right away.'
      } else {
        popUpMessage =
          'Congratulations! your mechanics are perfect, you can go straight to strengthening.'
      }
      this.setState({
        letterGrade,
        percentGrade,
        errorMessage,
        isTypeInvalid,
        isInhaleInvalid,
        isExhaleInvalid,
        popUpMessage,
      })
    } else {
      errorMessage =
        'Please enter your type & measurements so we can calculate your BIQ'
      this.setState({
        errorMessage,
        isTypeInvalid,
        isInhaleInvalid,
        isExhaleInvalid,
        isPopupActive,
        popUpMessage,
      })
    }
    // this.handleNext();
    this.submitEmail()
  }

  handleNext = event => {
    this.setState({
      isStepOneDone: true,
    })
    // this.sendEmail().then(data => console.log(data))
  }

  getLetterGrade() {
    const firstResult = this.state.inhale - this.state.exhale
    const secondResult = firstResult / this.state.exhale
    const percentGrade = secondResult * 1000
    let letterGrade = ''
    switch (this.state.type) {
      case '1':
        if (percentGrade < 60) {
          letterGrade = 'F'
        } else if (percentGrade < 80) {
          letterGrade = 'F'
        } else if (percentGrade < 100) {
          letterGrade = 'D'
        } else if (percentGrade < 120) {
          letterGrade = 'C'
        } else {
          letterGrade = 'C'
        }
        break
      case '2':
        if (percentGrade < 60) {
          letterGrade = 'C'
        } else if (percentGrade < 80) {
          letterGrade = 'C'
        } else if (percentGrade < 100) {
          letterGrade = 'B'
        } else if (percentGrade < 120) {
          letterGrade = 'A'
        } else {
          letterGrade = 'A'
        }
        break
      case '3':
        if (percentGrade < 60) {
          letterGrade = 'D'
        } else if (percentGrade < 80) {
          letterGrade = 'D'
        } else if (percentGrade < 100) {
          letterGrade = 'C/B'
        } else if (percentGrade < 120) {
          letterGrade = 'B'
        } else {
          letterGrade = 'B'
        }
        break

      default:
        break
    }
    return letterGrade
  }

  submitEmail = event => {
    if (process.env.IS_LIVE === 'true') {
      console.log(this)
      const cookies = new Cookies()
      var obj = {}
      obj.fields = []

      var emailObj = {}
      emailObj.name = 'email'
      emailObj.value = this.state.email

      var firstNameObj = {}
      firstNameObj.name = 'firstname'
      firstNameObj.value = this.state.firstName

      var lastNameObj = {}
      lastNameObj.name = 'lastname'
      lastNameObj.value = this.state.lastName

      var biqObj = {}
      biqObj.name = 'biq'
      biqObj.value = this.getLetterGrade()

      // 1 = Vertical
      // 2 = Horizontal
      // 3 = Hybrid
      let lomVal = ''
      switch (this.state.type) {
        case '1':
          lomVal = 'Vertical'
          break
        case '2':
          lomVal = 'Horizontal'
          break
        case '3':
          lomVal = 'Hybrid'
          break
        default:
          break
      }
      var lomObj = {}
      lomObj.name = 'lom'
      lomObj.value = lomVal

      var inhaleObj = {}
      inhaleObj.name = 'inhale'
      inhaleObj.value = this.state.inhale

      var exhaleObj = {}
      exhaleObj.name = 'exhale'
      exhaleObj.value = this.state.exhale

      var coachObj = {}
      coachObj.name = 'coach'
      coachObj.value = this.state.coachEmail

      var friendObj = {}
      friendObj.name = 'friend'
      friendObj.value = this.state.friendEmail

      let genderVal = ''
      switch (this.state.gender) {
        case '0':
          genderVal = 'Prefer Not To Answer'
          break
        case '1':
          genderVal = 'Female'
          break
        case '2':
          genderVal = 'Male'
          break
        case '3':
          genderVal = 'Non-Binary'
          break
        default:
          break
      }

      var genderObj = {}
      genderObj.name = 'gender'
      genderObj.value = genderVal

      let heightVal = ''
      if (this.state.isMetric) {
        heightVal = this.state.height1 + ' cm.'
      } else {
        heightVal =
          this.state.height1 +
          ' ft. ' +
          (this.state.height2 ? this.state.height2 + ' in.' : '')
      }
      var heightObj = {}
      heightObj.name = 'height'
      heightObj.value = heightVal

      let weightVal = ''
      if (this.state.isMetric) {
        weightVal = this.state.weight + ' kg.'
      } else {
        weightVal = this.state.weight + ' lbs.'
      }
      var weightObj = {}
      weightObj.name = 'weight'
      weightObj.value = weightVal

      var ageObj = {}
      ageObj.name = 'age'
      ageObj.value = this.state.age

      obj.fields.push(
        emailObj,
        firstNameObj,
        lastNameObj,
        biqObj,
        lomObj,
        inhaleObj,
        exhaleObj,
        coachObj,
        friendObj,
        genderObj,
        heightObj,
        weightObj,
        ageObj
      )
      obj.context = {}
      // obj.context.hutk = this.getCookie('hubspotutk');
      obj.context.hutk = cookies.get('hubspotutk')
      obj.context.pageUri = 'master.d28329zn8ivtzh.amplifyapp.com/biq'
      obj.context.pageName = 'BIQ Page'
      fetch(
        'https://api.hsforms.com/submissions/v3/integration/submit/8733114/6443243d-7fe4-4fcb-86ab-f719c356b50e',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(obj),
        }
      )
        .then(response => response.json())
        .then(data => console.log(data))
    }
    this.setState({
      isStepOneDone: true,
    })
    this.toggleShowPopUp()
  }

  async sendEmail() {
    let mailObj
    switch (this.state.letterGrade) {
      case 'A':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'A',
                meaningText1:
                  'You are elite as far as the mechanics of your breath! You are truly breathing diaphragmatically which means:',
                meaningText2:
                  'When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others.',
                meaningText3:
                  'You can control your anxiety better with patterned breathing (counted breathing patterns). You are breathing in a way that takes care of the vertebrae in your spine and digestive system.',
                meaningText4:
                  'You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1:
                  'I can breathe this way but I don’t. Does it still count?',
                faqText2: 'Yes!',
                faqText3: 'How will this change my Box Breathing?',
                faqText4:
                  'It will be significantly more efficient as a calming breath.',
                faqText5:
                  'Does this mean I am a circumferential 360-degree breather?',
                faqText6:
                  'You probably have some ability to breathe circumferentially, but we are going to make that even better.',
                faqText7: '',
                faqText8: '',
                whatNextText1:
                  'You can go straight to level 2, strengthening the muscles, in order to build your endurance. Strong breathing muscles means strong lungs. It means you can take big breaths quickly and that the 11 pounds of breathing muscles in your body are well conditioned (and therefore leave extra oxygen for your arms and legs). An exercised diaphragm is one that can brace quickly then breathe efficiently in a matter of fractions of seconds. It means that now you will detoxify and recover more quickly as you get stronger.',
                whatNextText2: '',
                whatNextText3: '',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break

      case 'B':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'B',
                meaningText1:
                  'You are good as far as the mechanics of your breath, however we’d like you to be great. You are well on your way to be truly breathing diaphragmatically. When you get to an A (which could be in a day or two with practice!), it means:',
                meaningText2:
                  'When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others.',
                meaningText3:
                  'You can control your anxiety better with patterned breathing (counted breathing patterns). You are breathing in a way that takes care of the vertebrae in your spine and digestive system.',
                meaningText4:
                  'You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1: 'What do I have to do to get to an A?',
                faqText2:
                  'Two things: 1. Really make sure you are truly a horizontal breather, no neck and shoulder movement for the assessment (in real life you can of course, given what you are doing or what posture you are in). 2. See if you can get your inhale to be a little wider (1/4 of an inch). And same with your exhale (yes, just ¼ of an inch). The WARM UP exercises and stretches will help with this. Practice a little then recalculate!',
                faqText3: 'I’d like to get a wider inhale but I get stuck',
                faqText4:
                  'See “intercostal stretch” – what is probably happening is that the muscles in between your ribs are holding you back.',
                faqText5: 'How many people get an A from the beginning?',
                faqText6:
                  'Very few. So that you are starting at a B is excellent.',
                faqText7: '',
                faqText8: '',
                whatNextText1:
                  'Work on getting to that A. Do Diaphragm Extensions, Cat Cow, Rock and Roll and Intercostal Stretch. Remind yourself throughout the day to relax your shoulders and breathe moving your hips. It’s just a little jump to an A! After that you’ll be able to strengthen your breathing muscles in order to build your endurance. Strong breathing muscles means strong lungs. It means you can take big breaths quickly and that the 11 pounds of breathing muscles in your body are well conditioned (and therefore leave extra oxygen for your arms and legs).',
                whatNextText2:
                  'An exercised diaphragm is one that can brace quickly then breathe efficiently in a matter of fractions of seconds. It means that now you will detoxify and recover more quickly as you get stronger.',
                whatNextText3: '',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break

      case 'C/B':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'C/B',
                meaningText1:
                  'You are good as far as the mechanics of your breath, however we’d like you to be great. You are well on your way to be truly breathing diaphragmatically. When you get to an A (which could be in a day or two with practice!), it means:',
                meaningText2:
                  'When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others.',
                meaningText3:
                  'You can control your anxiety better with patterned breathing (counted breathing patterns). You are breathing in a way that takes care of the vertebrae in your spine and digestive system.',
                meaningText4:
                  'You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1: 'What do I have to do to get to an A?',
                faqText2:
                  'Two things: 1. Really make sure you are truly a horizontal breather, no neck and shoulder movement for the assessment (in real life you can of course, given what you are doing or what posture you are in). 2. See if you can get your inhale to be a little wider (1/4 of an inch). And same with your exhale (yes, just ¼ of an inch). The WARM UP exercises and stretches will help with this. Practice a little then recalculate!',
                faqText3: 'I’d like to get a wider inhale but I get stuck',
                faqText4:
                  'See “intercostal stretch” – what is probably happening is that the muscles in between your ribs are holding you back.',
                faqText5: 'How many people get an A from the beginning?',
                faqText6:
                  'Very few. So that you are starting at a C/B is excellent.',
                faqText7: '',
                faqText8: '',
                whatNextText1:
                  'Work on getting to that A. Do Diaphragm Extensions, Cat Cow, Rock and Roll and Intercostal Stretch. Remind yourself throughout the day to relax your shoulders and breathe moving your hips. It’s just a little jump to an A! After that you’ll be able to strengthen your breathing muscles in order to build your endurance. Strong breathing muscles means strong lungs. It means you can take big breaths quickly and that the 11 pounds of breathing muscles in your body are well conditioned (and therefore leave extra oxygen for your arms and legs).',
                whatNextText2:
                  'An exercised diaphragm is one that can brace quickly then breathe efficiently in a matter of fractions of seconds. It means that now you will detoxify and recover more quickly as you get stronger.',
                whatNextText3: '',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break
      case 'C':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'C',
                meaningText1:
                  'Your diaphragm is not really doing it’s job. You are either using your neck and shoulders far more than you should and/or the movement of your diaphragm is inhibited. This could be from a number of reasons including tight intercostal muscles (between the ribs) and you’re mistakenly thinking a good breath should happen way on top of your body. You probably have at least one surprising symptom as a result of this: digestive problems, back pain or uncontrolled stress. Step one is to fix your form – your mechanics—so that you are using the right muscles and accessing the densest most oxygen rich part of your lungs. Once you get to an A (which not to worry, is not that far away!), we can strengthen the muscles.',
                meaningText2:
                  'When you are truly breathing diaphragmatically it means: When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others. You can control your anxiety better with patterned breathing (counted breathing patterns).',
                meaningText3:
                  'You are breathing in a way that takes care of the vertebrae in your spine and digestive system.',
                meaningText4:
                  'You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1:
                  'I’ve never gotten a C in anything. I’m mad at myself. How is this possible?',
                faqText2:
                  'You are not to blame. There are so many myths and misunderstandings around breathing that it’s not surprising that we have gotten confused. Once you understand that change this, you’ll see the words and cues that throw people into confusion.',
                faqText3: 'How long before I can get to an A?',
                faqText4:
                  'It could be 48 hours, it could be two weeks. But it won’t be months and months, we promise.',
                faqText5:
                  'I’m Horizontal (LOM- location of movement) but my inhale and exhale barely change.',
                faqText6:
                  'That is OK, it’s better that your location of movement be horizontal, changing the range will be easier. Hang with it.',
                faqText7:
                  'I have great (ROM - range of motion) but I’m Vertical.',
                faqText8:
                  'Well then, the ROM doesn’t really count because you are doing it with the wrong muscles. You have to focus on moving the breath down to the middle; don’t worry your grade will change even if it doesn’t feel as satisfying initially.',
                whatNextText1:
                  'You have to do the warmup stretches and correctives intensely and consistently. But don’t worry, change can come quickly. The correctives are designed so that you have at least one that “makes sense” and you can work off of. Doing your exercises and reminding yourself throughout the day is more important than one or two longer workouts. And don’t berate yourself when you find you have gone back to what you are used to, just autocorrect and keep moving.',
                whatNextText2:
                  'Once you get to an A we start strengthening your breathing muscles, but first you have to get the form, the mechanics right. Strong breathing muscles means strong lungs. It means you can take big breaths quickly and that the 11 pounds of breathing muscles in your body are well conditioned (and therefore leave extra oxygen for your arms and legs). An exercised diaphragm is one that can brace quickly then breathe efficiently in a matter of fractions of seconds. It means that now you will detoxify and recover more quickly as you get stronger.',
                whatNextText3: '',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break

      case 'D':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'D',
                meaningText1:
                  'The good news: You didn’t fail. The bad news: Your breathing mechanics are definitely not good. You are using neck and shoulders to pull your rib cage up, rather than your main breathing muscle to push it open so that you can access the lower most oxygen rich part of your lungs. Your ribcage is tight, so tight it doesn’t let you expand very much at all. Your location of motion (LOM) and Range of movement (ROM) are both subpar. But don’t despair, we won’t leave you hanging and it’s not terminal. It’s very very fixable and you can do it 100% on your own. Once you change your breath from the top of your body to the middle where it should be, and you widen your inhale and narrow your exhale, you’ll get to an A, and you can probably do this in a matter of weeks. What will that A mean?',
                meaningText2:
                  'When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others.',
                meaningText3:
                  'You can control your anxiety better with patterned breathing (counted breathing patterns). You are breathing in a way that takes care of the vertebrae in your spine and digestive system.',
                meaningText4:
                  'You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1:
                  'How is it possible that with all the professionals I’ve seen on one has told me this?',
                faqText2:
                  'There are several excellent clinicians and researchers who address the mechanics of the breath and we will list them for you so you can study them if you want. Their knowledge has not become mainstream until now. The schooling for the professionals who have worked with you, be they physical therapists or gym trainers or yoga teachers or even MDs, does not include this information to the extent that it should.',
                faqText3:
                  'Could this be contributing to my acid reflux, back pain and panic attacks?',
                faqText4: 'Absolutely.',
                faqText5: 'Do others breathe this way too?',
                faqText6:
                  'Absolutely. In fact, because of technology and our sitting too much, right now most people fall below a C!',
                faqText7: 'So how is this “reverse”?',
                faqText8:
                  'First understand what is normal: on the inhale the diaphragm flattens and pushes the ribs open, and your body widens. The opposite happens for the exhale. With reverse or paradoxical breathers, they breathe like they gasp, tightening the middle on the inhale. In our experience 1 in 10 people are paradoxical breathers.',
                whatNextText1:
                  'Make sure you know the 10 most critical points about breathing backwards and forwards. We’ll give you those soon. Think about each and then look at your body and see what it is doing when you breathe. And don’t neglect your story of how you got here. You may have been chubby as a kid; you may have been bracing your body for years because of anxiety.',
                whatNextText2:
                  'Once you get an A you can go to level 2, strengthening the muscles in order to build your endurance. Strong breathing muscles means strong lungs. It means you can take big breaths quickly and that the 11 pounds of breathing muscles in your body are well conditioned (and therefore leave extra oxygen for your arms and legs). An exercised diaphragm is one that can brace quickly then breathe efficiently in a matter of fractions of seconds. It means that now you will detoxify and recover more quickly as you get stronger.',
                whatNextText3: '',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break

      case 'F':
        mailObj = {
          personalizations: [
            {
              to: [
                {
                  email: this.state.email,
                  name: this.state.firstName + ' ' + this.state.lastName,
                },
              ],
              dynamic_template_data: {
                letterGrade: 'F',
                meaningText1:
                  'While this seems like terrible news, it’s actually good news. You have gotten this far in life breathing terribly, can you imagine how you are going to feel when you are breathing well. And “fixing” this can happen within a few weeks (less if you are really determined)!',
                meaningText2:
                  'You’ve probably been struggling with symptoms that do not respond to medical care: GERD or acid reflux, anxiety and or lower or upper back pain. There is sound research demonstrating that diaphragmatic breathing can help these things. Make sure you track and them see your progress.',
                meaningText3: 'Once you are truly breathing diaphragmatically:',
                meaningText4:
                  'When you do take a deep breath, you are using the most dense oxygen rich part of your lungs. Your lung capacity (size of your breath) is excellent. This also means you can oxygenate and energize more quickly than others. You can control your anxiety better with patterned breathing (counted breathing patterns). You are breathing in a way that takes care of the vertebrae in your spine and digestive system. You can lower your heart rate more quickly and consistently, control blood pressure and inflammation, this boosts your immune system.',
                faqText1: 'I’m exasperated, how could this have happened?',
                faqText2:
                  'Your breathing mechanics start to change when you are about 5 years old. You start feeling stress and so you brace your body, right where it should be expanding and contracting. You start imitating your parents and your superheroes. You start sucking in your little gut. You mistakenly think that is making you stronger. But it’s not your fault! There are so many myths and misunderstandings around breathing that it’s almost normal that we have gotten confused. Once you understand that change this, you’ll see the words and cues that throw people into confusion.',
                faqText3: 'How long with this take to fix?',
                faqText4:
                  'It will probably be 2 weeks to 21 days. If you get focused and really motivated it could be much shorter.',
                faqText5: 'Any tips?',
                faqText6:
                  'The movement of the hips and you’re looking down at your body are the two most important things. You’ll understand what I mean once you get the videos of the correctives.',
                faqText7: '',
                faqText8: '',
                whatNextText1:
                  'Make sure you understand why the breath you are taking is dysfunctional. Your understanding of your own anatomy is important, and although you think you might know, most folks don’t really understand how the diaphragm works. Be patient with yourself, you are going to be undoing a habit you had had for years, maybe decades.',
                whatNextText2:
                  'Make sure you know the 10 most critical points about breathing backwards and forwards. We’ll give you those soon. Think about each and then look at your body and see what it is doing when you breathe. And don’t neglect your story of how you got here. You may have been chubby as a kid; you may have been bracing your body for years because of anxiety.',
                whatNextText3:
                  'In order to get this grade, you are either a No-Haler (you take tiny sips of air) or it is very possible you are a Paradoxical breather. You are a paradoxical breather, which means you are breathing the opposite of how you were designed. In this case your inhale and the exhale are inverted (Inhale is less than the exhale, when inhale should always be bigger. Paradoxical breathers are also called reverse breathers or gaspers. Regardless, we can help you, you are in the right place.',
                exerciseURL: `https://form.jotform.com/210495355066255?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.email}`,
              },
              subject: 'Welcome to the Breathing IQ',
            },
          ],
          from: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          reply_to: {
            email: 'thebreathingiq@gmail.com',
            name: 'The Breathing IQ',
          },
          template_id: 'd-c2ae0964f7584ec6af59790ccaa87c0f',
        }
        break

      default:
        break
    }

    console.log(process.env.EMAIL_API_URL)
    const response = await fetch(process.env.EMAIL_API_URL + '/emails', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(mailObj),
    })
    if (response.status === 200) {
      this.setState({
        error: null,
        submitting: false,
        message: {
          fromEmail: '',
          subject: '',
          body: '',
        },
      })
    } else {
      const json = response.json()
      this.setState({
        error: json.error,
        submitting: false,
      })
    }
  }

  componentDidMount() {}

  handleStepperChoice = event => {
    this.setState({
      stepperChoice: event,
    })
  }
    // <div className="flex w-full lg:w-4/12">
  //                       <button
  //                         className="btn-sec-outline text-base w-full mt-2"
  //                         onClick={() => this.handleStepperChoice(2)}
  //                       >
  //                         Don't Have a Measuring Tape? No worries!
  //                       </button>
  //                     </div>
  render() {
    const imageList = this.props.data.allFile.edges
    return (
      <Layout>
        <section className="section-padding-large biq-bg-prim biq-text-white">
          <div className="container">
            <h1>Are you breathing intelligently?</h1>
            <div className="text-center font-semibold text-3xl mt-6">
              LET'S SEE WHERE YOU ARE: A QUICK 3-MINUTE ASSESSMENT
            </div>
            {this.state.isStepperActive === true ? (
              <div className="flex flex-col text-center place-self-center place-items-center justify-self-center w-full mt-6">
                {/* Start CTA */}
                {this.state.stepperChoice === 0 ? (
                  <div className="flex w-full flex-col">
                    <div className="text-xl">
                      Step One: Grab a measuring tape!
                    </div>
                    <div className="flex flex-col place-items-center justify-center mt-8">
                      <div className="flex w-full lg:w-4/12">
                        <button
                          className="btn-sec text-base w-full"
                          onClick={() => this.handleStepperChoice(1)}
                        >
                          Start Now
                        </button>
                      </div>
                      
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* Option 1:START STEPS */}
                {this.state.stepperChoice === 1 ? (
                  <div className="flex flex-col-reverse w-full mt-6">
                    <Steps
                      current={this.state.current}
                      style={{ color: 'white', width: '100px' }}
                      config={config}
                      handleSubmit={this.handleSubmit}
                    >
                      {/* <Step title="My Second Step" component={AgeStep} /> */}
                      <Step
                        title="First Time Step"
                        props={this.props}
                        component={FirstTimeStep}
                        parentState={this.state}
                        onChange={this.handleChange}
                        beforeStepChange={e => console.log(this)}
                      />
                      <Step
                        title="Gender And Age Step"
                        props={this.props}
                        component={GenderStep}
                        parentState={this.state}
                        onChange={this.handleChange}
                        beforeStepChange={console.log(this)}
                      />
                      <Step
                        title="Height and Weight Step"
                        props={this.props}
                        component={HeightWeightStep}
                        parentState={this.state}
                        onChange={this.handleChange}
                        beforeStepChange={e => console.log(e)}
                      />
                      <Step
                        title="BIQ Measurements Step"
                        props={this.props}
                        component={MeasurementStep}
                        parentState={this.state}
                        onChange={this.handleChange}
                        beforeStepChange={e => console.log(e)}
                      />
                      <Step
                        title="Name and Email Step"
                        props={this.props}
                        component={SubscribeStep}
                        parent={this}
                        parentState={this.state}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        beforeStepChange={e => console.log(e)}
                      />
                    </Steps>
                  </div>
                ) : (
                  ''
                )}

                {/* Option 2:DONT HAVE A MEASURING TAPE */}
                {this.state.stepperChoice === 2 ? (
                  <div className="flex flex-col-reverse w-full mt-6">
                    <Steps
                      style={{ color: 'white', width: '100px' }}
                      config={config}
                    >
                      <Step title="My Fourth Step" component={SubscribeStep} />
                    </Steps>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <>
                {this.state.isPopupActive === true ? (
                  <div className="flex flex-col place-items-end w-full xl:w-full xl:sticky">
                    <div className="flex flex-col w-full mt-10 p-10">
                      <div className="flex flex-col w-full px-20 py-12 mt-8 border-2 border-solid rounded-lg">
                        <div className="flex biq-text-4xl biq-font-semibold justify-center text-center">
                          Results: {this.getLetterGrade()}
                        </div>
                        <div className="flex biq-text-xl biq-font-semibold justify-center text-center">
                          {this.state.popUpMessage}
                        </div>
                        {this.state.letterGrade != null ? (
                          this.state.letterGrade.length > 0 ? (
                            <ReactWOW animation="fadeIn">
                              <div className="flex flex-col place-items-center justify-center w-full mt-4 py-4">
                                <ReactWOW animation="fadeIn">
                                  <div className="flex flex-col px-6">
                                    <span className="flex biq-text-white biq-text-lg mt-3">
                                      Send your results to a friend or your
                                      breathing coach.
                                    </span>
                                    <div class="flex w-full mt-3 opacity-50">
                                      Optional:
                                    </div>
                                    <div className="flex w-full mt-3">
                                      <input
                                        className="flex biq-text-black rounded-full px-3 w-full border-solid border-2"
                                        type="text"
                                        name="coachEmail"
                                        placeholder="Coach's Email"
                                        value={this.state.coachEmail}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="flex w-full mt-3">
                                      <input
                                        className="flex biq-text-black rounded-full px-3 w-full border-solid border-2"
                                        type="text"
                                        name="friendEmail"
                                        placeholder="Friend's Email"
                                        value={this.state.friendEmail}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="flex w-full mt-3">
                                      <button
                                        value="Submit"
                                        className="btn-sec w-full py-1"
                                        onClick={this.submitEmail}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </ReactWOW>
                                {this.state.isStepTwoDone === true ? (
                                  <div>
                                    You're all set! An email with breathing
                                    exercises will be sent to your coach or your
                                    friend shortly.
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </ReactWOW>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </section>
        <section className="biq-bg-gray">
          <div className="container-wide flex flex-row flex-wrap justify-center">
            <div className="flex flex-col place-items-end w-full xl:w-full biq-bg-gray">
              <div className="flex flex-col xl:ml-auto place-items-center">
                <div className="flex flex-col place-items-end w-full biq-bg-white">
                  <div className="flex flex-col ml-auto w-full place-items-center my-auto"></div>
                </div>
                <div className="flex flex-col biq-text-lg w-full mt-8">
                  {/* LOM */}
                  <div
                    id="lomContent"
                    className="flex flex-col justify-center mt-8 place-items-center p-2 xl:p-8 biq-bg-gray biq-text-lg biq-text-black w-full"
                  >
                    <h2 className="font-semibold biq-text-xl">
                      Location of Movement (LOM)
                    </h2>
                    <div className="flex">
                      Location of Movement (LOM): Where on your body do you feel
                      the most movement when you breathe? What direction is it
                      going in? What sort of a breather are you: Vertical,
                      Horizontal, or a little of both, that is to say a Hybrid?
                      Watch yourself or have someone else look at you. If you
                      are puffing up your chest and shoulders are rising, you
                      are a Vertical. If there is no activation of your neck and
                      upper pecs (you can see this yourself by looking in the
                      mirror) and the only expansion is between your nipples to
                      your hips, you are a Horizontal. Most adults are pure
                      Vertical Breathers or a Hybrid. Few, if any, are pure
                      Horzontal Breathers without training.{' '}
                    </div>
                  </div>
                  <div
                    id="romContent"
                    className="flex flex-col justify-center mt-8 place-items-center p-2 xl:p-8 biq-bg-gray biq-text-lg biq-text-black w-full"
                  >
                    <h2 className="font-semibold biq-text-xl">
                      Range of Motion (ROM)
                    </h2>
                    <div className="flex">
                      Measuring your “excursion” or “respiratory amplitude.”
                      Focus on two numbers: Inhale and note that number, exhale
                      and note that number. It’s as easy as measuring a neck for
                      a shirt, a waist for pants, or chest circumference for a
                      bra. See below for the specifics.{' '}
                    </div>
                    {/* IMAGES */}
                    <div className="flex flex-row flex-wrap mt-6">
                      <div className="flex flex-col w-full sm:w-1/2 xl:w-1/4 ">
                        <div
                          className="flex w-full"
                          style={{ minHeight: '168px' }}
                        >
                          <div className="w-full">
                            <Img
                              fluid={imageList[0].node.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                        {/* INFO */}
                        <div className="flex flex-col place-items-start w-full px-2">
                          <div className="flex place-self-center">Step One</div>
                          <div className="flex text-base">
                            Find the bottom of your front rib (it should be
                            directly below your nipple).
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full sm:w-1/2 xl:w-1/4 ">
                        <div
                          className="flex w-full"
                          style={{ minHeight: '168px' }}
                        >
                          <div className="w-full">
                            <Img
                              fluid={imageList[1].node.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                        {/* INFO */}
                        <div className="flex flex-col place-items-start w-full px-2">
                          <div className="flex place-self-center">Step Two</div>
                          <div className="flex text-base">
                            Loop a cloth measuring tape around your body at this
                            place. You might find looking down cumbersome; if
                            so, try doing this in front of a mirror.
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full sm:w-1/2 xl:w-1/4 ">
                        <div
                          className="flex w-full"
                          style={{ minHeight: '168px' }}
                        >
                          <div className="w-full">
                            <Img
                              fluid={imageList[2].node.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                        {/* INFO */}
                        <div className="flex flex-col place-items-start w-full px-2">
                          <div className="flex place-self-center">
                            Step Three
                          </div>
                          <div className="flex text-base">
                            Holding the tape snug so that it doesn’t droop in
                            the back, take a measurement of the circumference on
                            the inhale.
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full sm:w-1/2 xl:w-1/4 ">
                        <div
                          className="flex w-full"
                          style={{ minHeight: '168px' }}
                        >
                          <div className="w-full">
                            <Img
                              fluid={imageList[3].node.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                        {/* INFO */}
                        <div className="flex flex-col place-items-start w-full px-2">
                          <div className="flex place-self-center">
                            Step Four
                          </div>
                          <div className="flex text-base">
                            Keeping that tape snug, exhale completely. Take a
                            second measurement.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BIQ
const Navigation = props => {
  return <></>
}

const config = {
  navigation: {
    component: Navigation, // a React component with special props provided automatically
    location: 'before', // or after
  },
}

import React from 'react'
import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
import { RangeStepInput } from 'react-range-step-input'
export default class HeightWeightStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="flex flex-col place-items-center w-full">
           <ReactWOW animation="fadeIn" delay="0.2s">
        <div className="flex flex-row w-5/12 justify-center">
          <button
            className="btn-sec-outline w-full"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            name="isMetric"
            type="button"
            onClick={e => this.props.onChange({ target: {name: 'isMetric', value: true} })}
            value={this.props.parentState.isMetric}
          >
            Metric
          </button>
          <button
            className="btn-sec-outline w-full"
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            name="isMetric"
            type="button"
            onClick={e => this.props.onChange({ target: {name: 'isMetric', value: false} })}
            value={!this.props.parentState.isMetric}
          >
            Imperial
          </button>
        </div>

           </ReactWOW>

        <ReactWOW animation="fadeIn" delay="0.4s">
          <div className="text-3xl font-medium mt-6">Height</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="0.6s">
          <div className="flex flex-row place-items-center">
            {/* <RangeStepInput className="mt-3 mr-3"
                            name="height"
                            value={this.props.getState("height", "")}
                            onChange={this.props.handleChange} /> */}
            {this.props.parentState.isMetric === true ? (
              <>
                <input
                  style={{
                    color: 'black',
                    borderRadius: '5px',
                    padding: '8px',
                    width: '100px',
                    textAlign: 'right'
                  }}
                  type="number"
                  className="mt-3 mr-3 text-2xl"
                  name="height1"
              value={this.props.parentState.height1}
              onChange={e => this.props.onChange(e)}
                />
                <div className="flex mt-3 mr-3">cm.</div>
              </>
            ) : (
              <>
                <input
                  style={{
                    color: 'black',
                    borderRadius: '5px',
                    padding: '8px',
                    width: '100px',
                     textAlign: 'right'
                  }}
                  type="number"
                  className="mt-3 mr-3 text-2xl"
                  name="height1"
                  value={this.props.parentState.height1}
              onChange={e => this.props.onChange(e)}
                />
                <div className="flex mt-3 mr-3">ft.</div>
                <input
                  style={{
                    color: 'black',
                    borderRadius: '5px',
                    padding: '8px',
                    width: '100px',
                textAlign: 'right'
                  }}
                  type="number"
                  className="mt-3 mr-3 text-2xl"
                  name="height2"
                  value={this.props.parentState.height2}
              onChange={e => this.props.onChange(e)}
                />
                <div className="flex mt-3 mr-3">in.</div>
              </>
            )}
          </div>
        </ReactWOW>

        <ReactWOW animation="fadeIn" delay="0.8s">
          <div className="text-3xl font-medium mt-6">Weight</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1s">
          <div className="flex flex-row place-items-center">
            {/* <RangeStepInput
              className="mt-3 mr-3"
              name="weight"
              value={this.props.getState('weight', 0)}
              onChange={this.props.handleChange}
              max={1500}
            /> */}
            <input
              className="mt-3 mr-3 text-2xl"
              style={{
                color: 'black',
                borderRadius: '5px',
                padding: '8px',
                width: '100px',
                textAlign: 'right'
              }}
              max={1500}
              type="number"
              name="weight"
              value={this.props.parentState.weight}
              onChange={e => this.props.onChange(e)}
            />
            {this.props.parentState.isMetric === true ? (
              <div className="flex mt-3">kg.</div>
            ) : (
              <div className="flex mt-3">lbs.</div>
            )}
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1.2s">
          <div className="flex flex-row w-full justify-center mt-6">
            {this.props.current > 2 ? (
              <button
                className="btn-light-outline text-2xl font-medium mx-4 w-full lg:w-1/6"
                onClick={this.props.prev}
              >
                Previous
              </button>
            ) : (
              ''
            )}
            <button
              className="btn-light-outline text-2xl font-medium  mx-4 w-full lg:w-1/6"
              onClick={this.props.next}
            >
              Next
            </button>
          </div>
        </ReactWOW>
      </div>
    )
  }
}

import React from 'react'
import { StepComponentProps } from 'react-step-builder'
import ReactWOW from 'react-wow'
export default class GenderStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="flex flex-col place-items-center w-full">
        <ReactWOW animation="fadeIn" delay="0.2s">
          <div className="mt-6 text-3xl font-medium">Gender</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="0.2s">
          <div className="flex flex-row flex-wrap w-9/12 xl:w-7/12 justify-center place-items-center">
            <ReactWOW animation="fadeIn" delay="0.4s">
              <div className="w-full lg:w-6/12 flex justify-center place-items-center px-3 mt-4">
                <button
                  className="btn-sec-outline w-full"
                  name="gender"
                  value={this.props.state.gender}
                  onClick={e =>
                    this.props.onChange({
                      target: { name: 'gender', value: '1' },
                    })
                  }
                  value={this.props.parentState.gender === '1'}
                  type="button"
                  // value={this.props.getState('gender') === '1'}
                  // onClick={() => this.props.setState('gender', '1')}
                >
                  Female
                </button>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeIn" delay="0.6s">
              <div className="w-full lg:w-6/12 flex justify-center place-items-center px-3 mt-4">
                <button
                  className="btn-sec-outline w-full"
                  name="gender"
                  type="button"
                  onClick={e =>
                    this.props.onChange({
                      target: { name: 'gender', value: '2' },
                    })
                  }
                  value={this.props.parentState.gender === '2'}
                >
                  Male
                </button>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeIn" delay="0.8s">
              <div className="w-full lg:w-6/12 flex justify-center place-items-center px-3 mt-4">
                <button
                  className="btn-sec-outline w-full"
                  name="gender"
                  type="button"
                  onClick={e =>
                    this.props.onChange({
                      target: { name: 'gender', value: '3' },
                    })
                  }
                  value={this.props.parentState.gender === '3'}
                >
                  Non-Binary
                </button>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeIn" delay="1s">
              <div className="w-full lg:w-6/12 flex justify-center place-items-center px-3 mt-4">
                <button
                  className="btn-sec-outline w-full"
                  name="gender"
                  type="button"
                  onClick={e =>
                    this.props.onChange({
                      target: { name: 'gender', value: '0' },
                    })
                  }
                  value={this.props.parentState.gender === '0'}
                >
                  Prefer Not To Answer
                </button>
              </div>
            </ReactWOW>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1.2s">
          <div className="text-3xl font-medium mt-10">How old are you?</div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1.4s">
          <div className="flex flex-row justify-center place-items-center">
            {/* <RangeStepInput className="mt-3 mr-3"
                            name="height"
                            value={this.props.getState("height", "")}
                            onChange={this.props.handleChange} /> */}
            <input
              style={{
                color: 'black',
                borderRadius: '5px',
                padding: '4px',
                width: '100px',
                textAlign: 'right',
              }}
              className="mt-3 mr-3 text-2xl"
              type="number"
              name="age"
              placeholder="Age"
              value={this.props.parentState.age}
              onChange={e => this.props.onChange(e)}
              min="0"
            />
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeIn" delay="1.6s">
          <div className="flex flex-row w-full justify-center mt-6">
            {this.props.current > 2 ? (
              <button
                className="btn-light-outline text-2xl font-medium mx-4 w-1/6"
                onClick={this.props.prev}
              >
                Previous
              </button>
            ) : (
              ''
            )}
            <button
              className="btn-light-outline w-6/12 lg:w-3/12 text-2xl font-medium  mx-4 w-1/6"
              onClick={this.props.next}
            >
              Next
            </button>
          </div>
        </ReactWOW>
      </div>
    )
  }
}
